import mixpanel from 'mixpanel-browser';
import { toJS } from 'mobx';

import { client as api2Client } from './api2Client';
import { client as apolloClient } from './apolloClient';
import {
  createPaymentIntentMutation,
  startDonationCheckoutMutation,
  completeDonationCheckout,
  updatePaymentIntentMutation,
} from '../graphql/donationCheckout';
import {
  sendGiftsMutation,
  completeGiftCheckoutMutation,
  completeGiftCheckoutMutationV2,
} from '../graphql/giftCheckout';

const paymentOptionsSort = (a, b) => {
  // sort apple pay to the top per apple guidelines, then paypal behind it, then the rest
  if (a.paymentType === 'APPLE_PAY') return -1;
  if (a.paymentType === 'BRAINTREE') {
    if (b.paymentType === 'APPLE_PAY') {
      return 1;
    } else {
      return -1;
    }
  }
  return 0;
};

const generateCardPaymentOption = (card) => ({
  paymentType: card.accountType, // Can be ACH and Card
  displayString: `${card.nickname || card.brand} - ${card.last4}`,
  cardId: card.id,
  cardBrand: card.brand,
  cardName: card.last4,
  cardNickname: card.nickname,
});

// PaymentOptions as returned by checkoutDetails
const getFlatPaymentOptions = ({
  paymentOptions,
  currentCheckout,
  braintreeClientToken,
}) =>
  paymentOptions
    .filter((paymentOption) => {
      // the Add Bank Account and Paypal payment options are not supported
      // in mobile and are filtered out here
      if (
        paymentOption.paymentType === 'BALANCE' &&
        paymentOption.balance.total > 0
      ) {
        return true;
      }
      // if (paymentOption.paymentType === 'APPLE_PAY' && Platform.OS === 'ios') {
      //   return true;
      // }
      if (paymentOption.paymentType === 'BRAINTREE') {
        return true;
      }
      if (paymentOption.paymentType === 'CARD') {
        return true;
      }
      return false;
    })
    .flatMap((paymentOption) => {
      if (paymentOption.paymentType === 'APPLE_PAY') {
        return [{ paymentType: 'APPLE_PAY', displayString: 'Apple Pay' }];
      }
      if (braintreeClientToken && paymentOption.paymentType === 'BRAINTREE') {
        return [{ paymentType: 'BRAINTREE', displayString: 'PayPal' }];
      }
      if (paymentOption.paymentType === 'BALANCE') {
        return {
          paymentType: paymentOption.paymentType,
          displayString: `Cauze Balance: $${currentCheckout.balance / 100}`,
        };
      }
      // The paymentOption cards array contains stripe saved card AND bank tokens
      if (paymentOption.paymentType === 'CARD') {
        const cards = paymentOption.cards
          ? paymentOption.cards.map(generateCardPaymentOption)
          : [];
        return [
          ...cards,
          { paymentType: 'NEW_CARD', displayString: 'New Card' },
        ];
      }

      return null;
    })
    .filter(Boolean)
    .sort(paymentOptionsSort);

const getProcessingChargeMessage = (currentCheckout) => {
  const paymentType = currentCheckout.paymentType.toLowerCase();

  if (paymentType === 'plaid') {
    return (
      <>
        <div>
          Our credit card processor charges a mandatory processing fee of 2.2% +
          $0.30.
        </div>
        <br />
        <div>100% of your intended donation goes to the nonprofit.</div>
      </>
    );
  } else if (paymentType === 'card' || paymentType === 'stripe_cc') {
    if (currentCheckout.cardBrand === 'American Express') {
      return (
        <>
          <div>
            Our credit card processor charges a mandatory processing fee of
            3.5%.
          </div>
          <br />
          <div>100% of your intended donation goes to the nonprofit.</div>
        </>
      );
    } else {
      return (
        <>
          <div>
            Our credit card processor charges a mandatory processing fee of 2.2%
            + $0.30.
          </div>
          <br />
          <div>100% of your intended donation goes to the nonprofit.</div>
        </>
      );
    }
  } else if (paymentType === 'ach' || paymentType === 'stripe_ach') {
    return (
      <>
        <div>
          Our credit card processor charges a mandatory processing fee of 0.8%
          ($5.00 max).
        </div>
        <br />
        <div>100% of your intended donation goes to the nonprofit.</div>
      </>
    );
  } else if (paymentType === 'braintree') {
    return (
      <>
        <div>
          Our credit card processor charges a mandatory processing fee of 3.49%
          + $0.49.{' '}
        </div>
        <br />
        <div>100% of your intended donation goes to the nonprofit.</div>
      </>
    );
  } else if (paymentType === 'apple_pay') {
    return <div>Standard processing fees apply.</div>;
  }
};

const createPaymentIntent = async ({
  amount,
  paymentMethod,
  savePaymentMethod,
}) => {
  const options = {
    variables: {
      amount,
      paymentMethod,
      savePaymentMethod,
    },
    mutation: createPaymentIntentMutation,
    fetchPolicy: 'no-cache',
    errorPolicy: global.IS_DEV ? 'all' : 'none',
  };
  const res = await api2Client.mutate(options);
  return res.data?.createPaymentIntent;
};

const updatePaymentIntent = async ({ amount, intentId }) => {
  const options = {
    variables: {
      amount,
      intentId,
    },
    mutation: updatePaymentIntentMutation,
    fetchPolicy: 'no-cache',
    errorPolicy: global.IS_DEV ? 'all' : 'none',
  };
  const res = await api2Client.mutate(options);
  return res.data?.updatePaymentIntent;
};

const startPaymentProcess = async ({ store, checkoutType, variables }) => {
  if (checkoutType === 'donation') {
    store.awaitTokenCreation = true;
    const paymentType = store.currentCheckout.paymentType?.toLowerCase();

    const userDetails = {
      firstName: store.currentCheckout.firstName,
      lastName: store.currentCheckout.lastName,
      email: store.currentCheckout.email,
      zip: store.currentCheckout.zip || '',
    };

    if (!userDetails.firstName && userDetails.lastName) {
      userDetails.firstName = 'Giver';
    }

    // user details will be pulled from SSO request
    if (['paypal', 'apple_pay'].includes(paymentType)) {
      if (!userDetails.firstName) {
        userDetails.firstName = 'Gift';
      }
      if (!userDetails.lastName) {
        userDetails.lastName = 'Recipient';
      }
      if (!userDetails.email) {
        userDetails.email = 'giftrecipient@cauze.com';
      }
    }

    if (paymentType === 'braintree') {
      store.currentCheckout.paymentType = 'braintree';
    }

    let options = {
      variables: {
        request: {
          ...variables,
          userDetails,
        },
      },
      mutation: startDonationCheckoutMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      let result = await api2Client.mutate(options);
      store.awaitTokenCreation = false;
      store.updateCheckout({
        token: result.data.startDonationCheckout.token,
        splitAmount: result.data.startDonationCheckout.splitAmount,
      });

      mixpanel.track('Donation Checkout Transaction Started');

      if (paymentType === 'ach' && !store.currentCheckout.cardId) {
        return;
      }

      if (['stripe_cc', 'stripe_ach', 'card'].includes(paymentType)) {
        if (
          !store.currentCheckout.cardId ||
          store.currentCheckout.cardStripeId?.slice(0, 3) === 'pm_'
        ) {
          store.elements.submit();

          if (store.currentCheckout.paymentIntentId) {
            result = await updatePaymentIntent({
              amount: result.data.startDonationCheckout.splitAmount.total,
              intentId: store.currentCheckout.paymentIntentId,
            });
          } else {
            result = await createPaymentIntent({
              amount: result.data.startDonationCheckout.splitAmount.total,
              paymentMethod: store.currentCheckout.cardStripeId,
              savePaymentMethod: store.isAuthenticated !== false,
            });
          }

          store.updateCheckout({
            clientSecret: result.clientSecret,
            paymentIntentId: result.id,
          });
        }
      }

      if (paymentType === 'apple_pay') {
        if (store.currentCheckout.paymentIntentId) {
          result = await updatePaymentIntent({
            amount: result.data.startDonationCheckout.splitAmount.total,
            intentId: store.currentCheckout.paymentIntentId,
          });
        } else {
          result = await createPaymentIntent({
            amount: result.data.startDonationCheckout.splitAmount.total,
            paymentMethod: store.currentCheckout.cardStripeId,
            savePaymentMethod: store.isAuthenticated !== false,
          });
        }

        store.updateCheckout({
          clientSecret: result.clientSecret,
          paymentIntentId: result.id,
        });
      }

      if (
        [
          'balance',
          'braintree',
          'plaid',
          'stripe_cc',
          'stripe_ach',
          'card',
        ].includes(paymentType)
      ) {
        store.showConfirmationModal = true;
      }
    } catch (err) {
      if (err[0]?.message === 'Insufficient balance to complete donation') {
        store.popErrorModal(
          'Insufficient balance to complete donation, please select a lesser amount.',
        );
      }
      store.awaitTokenCreation = false;
    }
  } else if (checkoutType === 'gift') {
    store.awaitTokenCreation = true;
    let options = {
      variables: variables,
      mutation: sendGiftsMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      let result = await apolloClient.mutate(options);
      store.awaitTokenCreation = false;
      store.updateCheckout({
        token: result.data.sendGifts.token,
        splitAmount: result.data.sendGifts.splitAmount,
      });

      const paymentType = store.currentCheckout.paymentType?.toLowerCase();

      if (
        paymentType === 'apple_pay' ||
        (['stripe_cc', 'card'].includes(paymentType) &&
          (!store.currentCheckout.cardId ||
            store.currentCheckout.cardStripeId?.slice(0, 3) === 'pm_'))
      ) {
        if (paymentType !== 'apple_pay') {
          store.elements.submit();
        }

        if (store.currentCheckout.paymentIntentId) {
          result = await updatePaymentIntent({
            intentId: store.currentCheckout.paymentIntentId,
            amount: result.data.sendGifts.splitAmount.total,
          });
        } else {
          result = await createPaymentIntent({
            amount: result.data.sendGifts.splitAmount.total,
            paymentMethod: store.currentCheckout.cardStripeId,
            savePaymentMethod: store.isAuthenticated !== false,
          });
        }

        store.updateCheckout({
          paymentIntentId: result.id,
          clientSecret: result.clientSecret,
        });
      }

      if (
        ['balance', 'braintree', 'card', 'ach', 'stripe_cc'].includes(
          paymentType,
        )
      ) {
        store.showConfirmationModal = true;
      }
    } catch (err) {
      if (err?.[0]?.message === 'Insufficient balance to complete donation') {
        store.popErrorModal(
          'Insufficient balance to complete donation, please select a lesser amount.',
        );
      }
      store.awaitTokenCreation = false;
    }
  }
};

const confirmPayment = async ({ store }) => {
  if (
    ['card', 'stripe_cc'].includes(
      store.currentCheckout.paymentType?.toLowerCase(),
    )
  ) {
    const result = await store.stripe.confirmPayment({
      elements: store.currentCheckout.cardId ? undefined : store.elements,
      clientSecret: store.currentCheckout.clientSecret,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${window.location.origin}/checkout/success`,
        payment_method_data: {
          billing_details: {
            name: `${store.currentCheckout.firstName} ${store.currentCheckout.lastName}`,
            email: store.currentCheckout.email,
            phone: '',
            address: {
              city: '',
              country: 'US',
              state: '',
              postal_code: '',
              line1: '',
              line2: '',
            },
          },
        },
      },
    });

    if (result.error) {
      store.popErrorModal(result.error.message);
      return {};
    } else {
      store.updateCheckout({
        paymentIntentId: result.paymentIntent.id,
        paymentMethodId: result.paymentIntent.payment_method,
      });
    }

    return result;
  }

  return {};
};

const getCheckoutVars = async ({ store, checkoutType, auth = true }) => {
  if (checkoutType === 'donation') {
    const { token, cardId, stripeToken } = toJS(store.currentCheckout);
    const paymentType = store.currentCheckout.paymentType.toUpperCase();

    const variables = {
      checkoutTokenUuid: token,
      paymentType: paymentType,
    };

    if (['STRIPE_CC', 'STRIPE_ACH'].includes(paymentType) && !stripeToken) {
      variables.stripeSavedPaymentData = { paymentMethodId: parseInt(cardId) };
    }

    if (store.currentCheckout.paymentToken && paymentType === 'BRAINTREE') {
      variables.braintreePaymentData = {
        braintreeNonce: store.currentCheckout.paymentToken,
      };
    }

    if (paymentType === 'PAYPAL') {
      variables.paypalPaymentData = store.currentCheckout.paypalData;
    }

    if (['CARD'].includes(paymentType)) {
      const result = await confirmPayment({ store });

      if (!result?.paymentIntent) {
        return variables;
      } else {
        variables.stripeOnetimePaymentData = {
          paymentIntentId: store.currentCheckout.paymentIntentId,
          paymentMethodId: store.currentCheckout.paymentMethodId,
          savePaymentMethod: store.isAuthenticated !== false,
        };
        variables.paymentType = 'STRIPE_CC';
      }
    }

    if (['APPLE_PAY'].includes(paymentType)) {
      variables.stripeOnetimePaymentData = {
        paymentIntentId: store.currentCheckout.paymentIntentId,
        paymentMethodId: store.currentCheckout.paymentMethodId,
        savePaymentMethod: false,
      };
      variables.paymentType = 'STRIPE_CC';
    }

    return variables;
  } else if (checkoutType === 'gift') {
    const { token, stripeToken, cardId, cardStripeId } = toJS(
      store.currentCheckout,
    );
    const paymentType = store.currentCheckout.paymentType?.toLowerCase();

    const variables = {
      uuid: token,
      stripeToken,
      paypalData: store.currentCheckout.paypalData
        ? store.currentCheckout.paypalData
        : null,
      paymentData: store.currentCheckout.paymentData
        ? store.currentCheckout.paymentData
        : null,
      nickname: store.currentCheckout.cardNickname,
    };

    const isV2 =
      (cardStripeId && cardStripeId?.slice(0, 3) === 'pm_') || !cardStripeId;

    if (['card', 'ach'].includes(paymentType) && !isV2) {
      variables.cardId = cardId;
    }

    if (['card', 'stripe_cc'].includes(paymentType) && isV2) {
      try {
        const result = await confirmPayment({ store });

        if (!result?.paymentIntent) {
          return;
        }
      } catch (err) {
        // pass
      }

      variables.paymentData = {
        paymentToken: store.currentCheckout.paymentIntentId,
        paymentMethodId: store.currentCheckout.cardStripeId,
        paymentTokenType: 'INTENT',
        savePaymentMethod: auth
          ? !Boolean(store.currentCheckout.cardStripeId)
          : undefined,
      };
    }

    if (['apple_pay'].includes(paymentType)) {
      variables.paymentData = {
        paymentToken: store.currentCheckout.paymentIntentId,
        paymentMethodId: store.currentCheckout.cardStripeId,
        paymentTokenType: 'INTENT',
        savePaymentMethod: undefined,
      };
    }

    return variables;
  }
};

const completePaymentProcess = async ({
  store,
  checkoutType,
  variables,
  auth = true,
}) => {
  if (checkoutType === 'donation') {
    const options = {
      variables: { request: variables },
      mutation: completeDonationCheckout,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    };

    try {
      const result = await api2Client.mutate(options);
      store.checkoutSuccess = result.data.completeDonationCheckout;

      mixpanel.track('Donation Checkout Transaction Completed');

      if (result.data?.completeDonationCheckout?.rewardAmount) {
        store.showRewardModal = true;
      }

      store.awaitCompleteCheckout = false;
      store.showConfirmationModal = false;

      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      }

      return true;
    } catch (errors) {
      store.showConfirmationModal = false;
      store.awaitCompleteCheckout = false;

      if (Array.isArray(errors)) {
        if (errors[0].message) {
          store.popErrorModal(errors[0].message);
          return;
        }
      } else {
        store.popErrorModal(
          'There was a problem with your transaction. Support has been notified.',
        );
      }

      return false;
    }
  } else if (checkoutType === 'gift') {
    let result;

    if (!store.currentCheckout.paymentIntentId || !auth) {
      delete variables.paymentData.savePaymentMethod;

      const options = {
        variables,
        mutation: completeGiftCheckoutMutation,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      };
      result = await apolloClient.mutate(options);
    } else {
      const options = {
        variables,
        mutation: completeGiftCheckoutMutationV2,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };
      result = await api2Client.mutate(options);
    }

    store.checkoutSuccess = result.data.completeGiftCheckout;
    store.awaitCompleteCheckout = false;
    store.showConfirmationModal = false;

    return result;
  }
};

export {
  paymentOptionsSort,
  getFlatPaymentOptions,
  getProcessingChargeMessage,
  createPaymentIntent,
  startPaymentProcess,
  confirmPayment,
  completePaymentProcess,
  getCheckoutVars,
  updatePaymentIntent,
};

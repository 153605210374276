import { PaymentElement } from '@stripe/react-stripe-js';

import stripeOptions from 'config/stripe';

const AddCard = ({ onAddCard, showAddCard, setShowAddCard, isCheckout }) => {
  return (
    <div className="saved-add-card">
      <button type="button" onClick={() => setShowAddCard(!showAddCard)}>
        ADD A DEBIT / CREDIT CARD
      </button>
      {showAddCard && (
        <>
          <PaymentElement options={stripeOptions.cardElement} />
          {!isCheckout && (
            <button
              style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
              onClick={onAddCard}
              className="saved-add-card-btn"
            >
              ADD CARD
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default AddCard;

/* eslint-disable no-console */
import { observable, action, makeObservable, runInAction } from 'mobx';
import mixpanel from 'mixpanel-browser';

import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';

import uiStore from './UiStore';

import {
  paymentOptionsQueryV2,
  disassociatePaymentMethodMutation,
  addCardMutation,
  verifyBankAccountMutation,
  setAutopayMutation,
  addBankAccountMutation,
  createSetupIntentMutation,
  associatePaymentMethodMutation,
  updateDefaultPaymentMethodMutation,
} from '../graphql/paymentOptions';

import { plaidLinkTokenQuery } from '../graphql/giftCheckout';

class WalletStore {
  @observable loading = true;
  @observable isError = false;
  @observable loadingPaymentMethods = true;
  @observable balance = 0;
  @observable cards = [];
  @observable recurringDepositDay;
  @observable recurringAmount;
  @observable recurringCardId;
  @observable hasAutopayEnabled = false;
  @observable userContext = {};

  setStripeObject = (stripe) => {
    this.stripe = stripe;
  };

  getInitial = ({ userContext }) => {
    this.userContext = userContext;
    this.getPaymentMethods({ userContext });
  };

  plaidValidate = () => {
    return true;
  };

  @action getPaymentMethods = async ({ userContext = {} }) => {
    const options = {
      variables: { includePending: true },
      query: paymentOptionsQueryV2,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await api2Client.query(options);
      this.loadingPaymentMethods = false;
      runInAction(() => {
        if (
          result.data.paymentOptions &&
          result.data.paymentOptions.length > 0
        ) {
          if (result.data.paymentOptions[0].paymentType !== 'balance') {
            this.balance = 0;
          }

          this.cards = [];
          result.data.paymentOptions.forEach((paymentOption) => {
            if (paymentOption.paymentType === 'balance') {
              this.balance = paymentOption.balance.total;
              this.recurringCardId =
                paymentOption.balance.recurringPaymentMethodId;
              this.recurringDepositDay =
                paymentOption.balance.recurringDepositDay;
              this.recurringAmount = paymentOption.balance.recurringAmount;
            }
            if (
              paymentOption.paymentType === 'stripe_cc' ||
              paymentOption.paymentType === 'stripe_ach'
            ) {
              this.cards = this.cards.concat(
                paymentOption.paymentMethods || [],
              );

              if (userContext.companyId) {
                this.hasAutopayEnabled =
                  paymentOption.paymentMethods.filter(
                    (card) => card.autopayEnabled,
                  ).length > 0;
              }
            }
          });
        }
      });
    } catch (err) {
      this.loadingPaymentMethods = false;
    }
  };

  @action registerNewCard = async ({ userContext = {}, elements, stripe }) => {
    try {
      await elements.submit();

      // get payment setup client secret
      let options = {
        variables: { paymentMethodTypes: ['CARD'] },
        query: createSetupIntentMutation,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };
      let result = await api2Client.query(options);
      const { clientSecret } = result.data.createSetupIntent;

      const _stripe = stripe || this.stripe;

      const cardResult = await _stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.origin}/donate`,
          payment_method_data: {
            billing_details: {
              name: '',
              email: '',
              phone: '',
              address: {
                city: '',
                country: 'US',
                state: '',
                postal_code: '',
                line1: '',
                line2: '',
              },
            },
          },
        },
      });

      if (cardResult.error) {
        uiStore.showNotification({
          body: 'Could not add your card. Please try again.',
          type: 'ERROR',
        });
        return false;
      } else {
        let options = {
          variables: { setupIntentId: cardResult.setupIntent.id },
          query: associatePaymentMethodMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        await api2Client.query(options);
        await this.getPaymentMethods({ userContext });
        return true;
      }
    } catch (err) {
      uiStore.showNotification({
        body: 'Could not add your card. Please try again.',
        type: 'ERROR',
      });
    }

    return false;
  };

  @action removeCard = async ({ cardId, userContext }) => {
    const options = {
      variables: {
        cardId,
      },
      mutation: disassociatePaymentMethodMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      await api2Client.mutate(options);
      this.getPaymentMethods({ userContext });
    } catch (err) {
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
  };

  // for adding bank account through plaid/ donationCheckout
  @action addBankAccount = async ({
    publicToken,
    metadata,
    nickname,
    userContext,
  }) => {
    const options = {
      variables: {
        plaidToken: publicToken,
        accountId: metadata.accounts[0]?.id,
        nickname,
        userContext: userContext,
      },
      mutation: addBankAccountMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    const res = await apolloClient.mutate(options);
    this.getPaymentMethods({ userContext: userContext });
    return res;
  };

  // for adding bank account through stripe/ wallet
  @action addAch = async ({ achData, userContext }) => {
    try {
      const res = await this.stripe.createToken('bank_account', achData);
      if (res.errors) {
        res.errors.forEach((err) => {
          uiStore.showNotification({
            body: err.message,
            type: 'ERROR',
          });
        });
        return false;
      }
      if (res.token) {
        const options = {
          variables: {
            stripeToken: res.token.id,
            userContext: userContext,
          },
          mutation: addCardMutation,
          fetchPolicy: 'no-cache',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        await apolloClient.mutate(options);
        await this.getPaymentMethods({
          userContext,
        });
        uiStore.showNotification({
          body: 'Bank account added, verify here once transactions appear on statement.',
          type: 'SUCCESS',
        });
        return true;
      } else {
        throw new Error('No Token');
      }
    } catch (err) {
      console.log(err);
      uiStore.showNotification({
        body: 'Could not add your bank account. Please try again.',
        type: 'ERROR',
      });
    }
  };

  @action verifyAch = async ({ cardId, amount1, amount2, userContext }) => {
    try {
      const options = {
        variables: {
          cardId,
          amount1: parseInt(amount1),
          amount2: parseInt(amount2),
          userContext,
        },
        mutation: verifyBankAccountMutation,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      };
      const res = await apolloClient.mutate(options);

      if (res.errors) {
        res.errors.forEach((err) => {
          uiStore.showNotification({
            body: err.message,
            type: 'ERROR',
          });
        });
        return false;
      }
      await this.getPaymentMethods({
        userContext,
      });
      return true;
    } catch (err) {
      console.log(err);
    }
  };

  @action generatePlaidLinkToken = async () => {
    const options = {
      query: plaidLinkTokenQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      this.plaidLinkToken = await apolloClient
        .query(options)
        .then((res) => res.data.plaidLinkToken);
    } catch (err) {
      console.log(err);
    }
  };

  @action onPlaidStart = () => {
    mixpanel.track('Donation Checkout Started with Plaid');
  };

  // token is the public token returned from the modal, which we send as part of completeCheckout
  // for the server to validate against stripe for the transaction.
  //
  // Very similar behavior to paypal
  @action onPlaidSuccess = async (token, metadata) => {
    this.checkoutLoading = true;
    try {
      await walletStore.addBankAccount({
        publicToken: token,
        metadata: metadata,
        userContext: this.userContext,
      });
    } catch (error) {
      this.checkoutLoading = false;
      this.onPlaidError(error);
    }
  };

  @action onPlaidError = (error, _metadata) => {
    mixpanel.track('Donation Checkout Error with Plaid');
    this.checkoutLoading = false;
    console.log(error);
  };

  @action onPlaidExitEvent = (_eventName, _metadata) => {
    mixpanel.track('Donation Checkout User Closed Plaid Modal');
    this.checkoutLoading = false;
  };

  @action setAutopayEnabled = async ({
    autopayEnabled,
    cardId,
    userContext,
  }) => {
    try {
      const options = {
        variables: {
          autopayEnabled,
          cardId,
          userContext,
        },
        mutation: setAutopayMutation,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      };
      const res = await apolloClient.mutate(options);

      if (res.errors) {
        res.errors.forEach((err) => {
          uiStore.showNotification({
            body: err.message,
            type: 'ERROR',
          });
        });

        return false;
      }

      this.getPaymentMethods({ userContext }).then((_res) => {
        uiStore.showNotification({
          body: `ACH autopay is now ${autopayEnabled ? 'enabled' : 'disabled'}`,
          type: 'SUCCESS',
        });
        this.hasAutopayEnabled = autopayEnabled;
      });
    } catch (err) {
      uiStore.showNotification({
        body: 'There was an issue connecting your ACH account for autopay. Support has been notified.',
        type: 'ERROR',
      });
      console.log(err);
    }
  };

  @action updateDefaultCard = async ({ cardId, userContext }) => {
    const options = {
      variables: {
        cardId,
      },
      mutation: updateDefaultPaymentMethodMutation,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      this.cards = this.cards.map((card) => ({
        ...card,
        isDefault: card.id === cardId,
      }));

      await api2Client.mutate(options);
      this.getPaymentMethods({ userContext });
    } catch (err) {
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    }
  };

  @action onUnmount = () => {
    this.isError = false;
    this.balance = 0;
    this.cards = [];
  };

  constructor() {
    makeObservable(this);
  }
}

const walletStore = new WalletStore();

export default walletStore;

import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useStripe } from '@stripe/react-stripe-js';

import Navbar from 'components/page/Navbar/Navbar';
import FooterNew from 'components/page/Footer/FooterNew';
import AmountSelector from 'components/AmountSelector/AmountSelector';

import withUserContext from 'behaviors/withUserContext';
import Currency from 'components/Currency/Currency';
import SavedPaymentMethods from 'components/SavedPaymentMethods/SavedPaymentMethods';

const AddFundsCard = ({ children, title }) => {
  return (
    <div className="add-funds-card">
      {Boolean(title) && <div className="add-funds-card-title">{title}</div>}
      <div>{children}</div>
    </div>
  );
};

const AddFundsView = ({
  profileStore,
  walletStore,
  addFundsGiftCheckoutStore,
}) => {
  const activeEntity = profileStore.activeEntity;

  const stripe = useStripe();
  walletStore.setStripeObject(stripe);
  addFundsGiftCheckoutStore.setStripeObject(stripe);

  useEffect(() => {
    walletStore.getInitial({
      userContext: { userId: activeEntity.id },
    });
    addFundsGiftCheckoutStore.generatePlaidLinkToken();
  }, []);

  return (
    <div className="add-funds-view">
      <Navbar />
      <div className="add-funds-view-body">
        <div className="add-funds-view-main">
          <div className="add-funds-view-title">Add Funds</div>
          <div className="add-funds-view-desc">
            Adding funds to your account makes it easy to provide gifts,
            matches, or direct donations in the name of your account.
          </div>
          <AddFundsCard title="1. How much do you want to add to your balance?">
            <AmountSelector
              placeholder="$ custom amount"
              defaultAmount={1000}
              selectedAmount={addFundsGiftCheckoutStore.currentCheckout.amount}
              onAmountUpdate={(amt) =>
                addFundsGiftCheckoutStore.updateCheckout({
                  amount: amt,
                })
              }
            />
          </AddFundsCard>
          <AddFundsCard title="2. Select a Payment Method">
            <SavedPaymentMethods
              isCheckout
              walletStore={walletStore}
              cards={walletStore.cards}
              addFundsGiftCheckoutStore={addFundsGiftCheckoutStore}
              userId={activeEntity.id}
            />
          </AddFundsCard>
          <button
            className="add-funds-submit"
            onClick={() => {
              addFundsGiftCheckoutStore.startCheckout();
            }}
          >
            Add Funds
          </button>
          <div>
            <div>
              You can also send a check to us. We ask that the minimum check
              size be $5,000. Send to:
            </div>
            <br />
            <div>Cauze Charitable Fund</div>
            <div>1306 West Eastman Street</div>
            <div>Boise, ID 83702</div>
            <br />
            <div>
              In the memo line, please put the name of the account the funds are
              going to. Please allow 5-7 days for the check to process and the
              balance to be added to your account.
            </div>
          </div>
        </div>
        <div className="balance-total">
          <AddFundsCard>
            <div className="balance-total-amount">
              <Currency
                showDollarSign
                showCents
                amount={activeEntity.balance.total}
              />
            </div>
            <div className="balance-current-balance">Current Balance</div>
          </AddFundsCard>
        </div>
      </div>
      <FooterNew />
    </div>
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'walletStore',
    'addFundsGiftCheckoutStore',
  )(observer(AddFundsView)),
);

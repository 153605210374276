import React, { Fragment, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import classnames from 'classnames';

import useKeyboardEvent from '../../hooks/useKeyboardEvent';
import SendUserGiftsModal from 'components/modals/SendUserGiftsModal/SendUserGiftsModal';
import UserSearchModal from 'components/modals/UserSearchModal/UserSearchModal';
import CheckoutWithGiftModal from 'components/modals/CheckoutWithGiftModal/CheckoutWithGiftModal';
import CountrySelectorModal from 'components/modals/CountrySelectorModal/CountrySelectorModal';
import VerifyAchModal from 'components/modals/VerifyAchModal/VerifyAchModal.js';
import LoginModal from 'components/modals/LoginModal/LoginModal.js';
import FundGroupModal from 'components/modals/FundGroupModal/FundGroupModal.js';
import ConfirmModal from 'components/modals/ConfirmModal/ConfirmModal.js';
import ContextSelectorModal from 'components/modals/ContextSelectorModal/ContextSelectorModal';
import EntityListModal from 'components/modals/EntityListModal/EntityListModal';
import FollowFollowingModal from 'components/modals/FollowFollowingModal/FollowFollowingModal';
import TermsModal from 'components/modals/TermsModal/TermsModal';
import FavoriteNonprofitModal from 'components/modals/FavoriteNonprofitModal/FavoriteNonprofitModal';
import TermsOfUseModal from 'components/modals/TermsOfUseModal/TermsOfUseModal';
import PrivacyPolicyModal from 'components/modals/PrivacyPolicyModal/PrivacyPolicyModal';

const ModalNotificationProvider = ({ uiStore, children }) => {
  useKeyboardEvent('Escape', () => {
    uiStore.closeModal();
    uiStore.closeNotification();
  });

  const modalProps = toJS(uiStore.modalProps);

  useEffect(() => {
    if (uiStore.modal) {
      document.querySelector('html').style.overflowY = 'hidden';
    } else {
      document.querySelector('html').style.overflowY = 'scroll';
    }

    return () => {
      document.querySelector('html').style.overflowY = 'scroll';
    };
  }, [uiStore.modal]);

  return (
    <Fragment>
      {uiStore.notification && (
        <div
          className={classnames('notification app-notification', {
            'is-success': uiStore.notification.type === 'SUCCESS',
            'is-danger': uiStore.notification.type === 'ERROR',
          })}
        >
          <button
            onClick={() => uiStore.closeNotification()}
            className="delete"
          ></button>
          {uiStore.notification.body}
        </div>
      )}
      {uiStore.modal === 'CONTEXT_SELECTOR' && (
        <ContextSelectorModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'CONFIRM' && (
        <ConfirmModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'SEND_GIFTS' && (
        <SendUserGiftsModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'FAVORITE_NONPROFITS' && (
        <FavoriteNonprofitModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'FUND_GROUP' && (
        <FundGroupModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'USER_SEARCH' && (
        <UserSearchModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'COUNTRY_SELECTOR' && (
        <CountrySelectorModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'CHECKOUT_WITH_GIFT' && (
        <CheckoutWithGiftModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'LOGIN' && (
        <LoginModal {...modalProps} isOpen onToggleClose={uiStore.closeModal} />
      )}
      {uiStore.modal === 'VERIFY_ACH' && (
        <VerifyAchModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'ENTITY_LIST' && (
        <EntityListModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'FOLLOW_FOLLOWING' && (
        <FollowFollowingModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'TERMS' && (
        <TermsModal {...modalProps} isOpen onToggleClose={uiStore.closeModal} />
      )}
      {uiStore.modal === 'TERMS_OF_USE' && (
        <TermsOfUseModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {uiStore.modal === 'PRIVACY_POLICY' && (
        <PrivacyPolicyModal
          {...modalProps}
          isOpen
          onToggleClose={uiStore.closeModal}
        />
      )}
      {children}
    </Fragment>
  );
};

export default inject('uiStore')(observer(ModalNotificationProvider));
